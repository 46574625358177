import React from "react"
import LayoutXT from "../components/LayoutXT"

export default function Erbschein() {
  return (
    <LayoutXT>
      <h1 className="left">Erbschein</h1>
      <div className="rem-of-13 gray-a lheight18">
        Wir beraten Sie auch gerne rund um den Erbschein, insbesondere über die
        Bedeutung sowie Reichweite eines solchen Erbscheins. Unser
        Leistungsspektrum umfasst dabei die Beantragung von Erbscheinen sowie
        die Abwehr von unberechtigt erteilten Erbscheinen vor dem
        Nachlassgericht.
      </div>
    </LayoutXT>
  )
}
